(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-interval/assets/javascripts/use-interval.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-interval/assets/javascripts/use-interval.js');
'use strict';

const {
  useEffect,
  useRef,
  useState
} = React;
const useInterval = (callback, delay, firstCallImmediately, resetIncrementor) => {
  const [intervalId, setIntervalId] = useState(null);
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    if (firstCallImmediately && resetIncrementor) {
      savedCallback.current();
    }
  }, [resetIncrementor, firstCallImmediately]);
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    const id = setInterval(tick, delay);
    setIntervalId(id);
    return () => clearInterval(id);
  }, [delay, resetIncrementor]);
  return intervalId;
};
setGlobal('svs.components.lbUtils.useInterval', useInterval);

 })(window);